import React from 'react' // eslint-disable-next-line
import LOGO01 from '../LOGO01.svg' // eslint-disable-next-line
import LOGO02 from '../LOGO02.svg' // eslint-disable-next-line
import LOGO03 from '../LOGO03.svg' // eslint-disable-next-line
import Cuarto from '../Images/Cuarto.jpg' // eslint-disable-next-line
import {Button, Icon} from 'semantic-ui-react' // eslint-disable-next-line
import { motion } from 'framer-motion';

import 'semantic-ui-css/semantic.min.css'
import '../styles/Services.css'


function Services() {
  
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{opacity: 0}}>
    <div className="wrapper-services">
      <div className="section-1">
        <h1 className="services-headline">"Estilo, innovando tu mundo"</h1>
        <h2 className="subheadline"> - FF360</h2>
        <div className="subsection-1">
          <div className="sub-item-1">
            <img src={LOGO02} className='image' alt='Fizzel Furniture 360 (FF360)'/>
          </div>
          <div className="sub-item-2">
            <p className="services-paragraph-1">
              Somos una fábrica establecida desde el 2017 de mobiliarios, residencial y comercial. Para la elaboración de la misma, utilizamos variedad de materiales tales como Madera,
              Hydrófugo y PVC. Incorporamos la cristalería en nuestros muebles de ser solicitado. Acompañado con herrajes Europeos de alta calidad, recocido en la industria por ejemplo
              HAFELE, HETTICH, KESSBOHMER y más.
              En el tema de las encimeras utilizamos Laminado, Cuarzo y Fenolifico.
            </p>
            {/* <Button size="massive" className="getQuote">Get a Quote Today!</Button> */}
          </div>
        </div>
      </div>
      {/* <div className="section-2">
        <Button>
          <h1 className="accordion">
            AWARDS / CERTS (CTA) (Credibility)<Icon name="triangle down"></Icon>
          </h1>
        </Button>
      </div> */}
      {/* <div className="section-3">
        <div className="sec-item-1">
          <p className="services-paragraph-2">
              Incorporamos la cristalería en nuestros muebles de ser solicitado. Acompañado con herrajes Europeos de alta calidad, recocido en la industria por ejemplo
              HAFELE, HETTICH, KESSBOHMER y más.
              En el tema de las encimeras utilizamos Laminado, Cuarzo y Fenolifico.
          </p>
        </div>
      </div> */}
      {/* <div className="subsection-1">
          <div className="sub-item-1">
            <img src={LOGO01} className='image' alt='Fizzel Furniture 360 (FF360)'/>
          </div>
          <div className="sub-item-2">
            <p className="services-paragraph-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim,
            massa sit amet hendrerit tempus, diam nunc fringilla turpis, eget vehicula metus
            massa non ex. Morbi porta, velit a bibendum dapibus, metus quam porta felis,
            ut dignissim odio magna varius mauris. Nam tincidunt quam a urna laoreet rhoncus.
            Vivamus tortor metus, tempor sit amet blandit eget, rhoncus at augue.
            Cras ac felis mauris. Nulla gravida eros condimentum risus vulputate,
            et aliquet diam tincidunt.
            </p>
            <Button size="massive" className="getQuote">Get a Quote Today!</Button>
          </div>
      </div> */}
      <div className="section-4">
        <h1 className="work-Headline">Que nuestro trabajo hable por si mismo, visita nuestras redes sociales y échale un vistazo a nuestros proyectos:</h1>
        <div className="sub-section-4">
          <a href='https://www.facebook.com/fizzelfurniture360/' target="_blank" rel="noopener noreferrer"><i class="massive facebook icon"></i></a>
          <a href='https://www.instagram.com/fizzelfurniture360/' target="_blank" rel="noopener noreferrer"><i class="massive instagram icon"></i></a>
          <a href='https://api.whatsapp.com/send?phone=17875853623&amp;app=facebook&amp;entry_point=page_cta' target="_blank" rel="noopener noreferrer"><i class="massive whatsapp icon"></i></a>
        </div>
      </div>
      {/* <div className="section-5">
        <h1 className="testimonials-Headline">TESTIMONIOS</h1>
        <div className="testimonials">
          <div className="subsection-5-item-1">
            item 1
          </div>
          <div className="subsection-5-item-2">
            item 2
          </div>
        </div>
      </div> */}
      {/* <div className="section-6">
        
      </div> */}
    </div>
    </motion.div>
  );
}
export default Services;